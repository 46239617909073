
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapMutations } from "vuex";

export default Vue.extend({
  name: "crm-chat-main",
  methods: {
    ...mapMutations(["mutDrawerExpand"]),
    scrollToTop() {
      if ((this as any).$refs["scrollable"]) {
        (this as any).$refs["scrollable"].scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {
    (this as any).$parent.$parent.$parent.topBar = true;
    (this as any).scrollToTop();
    if (this.$vuetify.breakpoint.mobile == false) {
      (this as any).$parent.$parent.$parent.drawer = true;
    }
  },
});
